/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper'; 



@font-face {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/Cairo-Regular.ttf');
  }

  ion-content {
    --background: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary));
    font-family: 'Cairo';

}


.item .sc-ion-label-md-h{
    font-family: 'Cairo';  
}

ion-item{
    font-family: 'Cairo';  
    padding: 5px;
}
 
ion-content {
    --background: none;
    background-image: url('/assets/img/bg1.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;  
    font-family: 'Cairo';
}

ion-button {
    font-family: 'Cairo';
}
 
ion-item {
    --ion-item-background: transparent;
}

ion-label {
    font-family: 'Cairo';
}
label {
    font-family: 'Cairo';
}
// backgrounds //
.bg-white {
    background-color: #fff;
}

.bg-light {
    background-color: var(--ion-color-light);
}

.bg-dark {
    background-color: var(--ion-color-dark);
}

.bg-primary {
    background-color: var(--ion-color-primary);
}

.bg-secondary {
    background-color: var(--ion-color-secondary);
}

.bg-tertiary {
    background-color: var(--ion-color-tertiary);
}

.bg-profile {
    background-image: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary))
}

.bg-main {
    background-image: linear-gradient(-135deg, var(--ion-color-medium), var(--ion-color-light))
}

// Helpers //
.w100 {
    width: 100%;
}

.bold,
.fw500 {
    font-weight: 500 !important;
}

.fw700 {
    font-weight: 700 !important;

}

.text08 {
    font-size: .8rem;
}

.text1 {
    font-size: 1rem !important;
}

.text12 {
    font-size: 1.2rem;
}

.text15 {
    font-size: 1.5rem;
}

.text20 {
    font-size: 2rem;
}

.text-white {
    color: #fff;
}

.text-primary {
    color: var(--ion-color-primary);
}

.text-secondary {
    color: var(--ion-color-secondary);
}

.text-dark {
    color: var(--ion-color-dark);
}

// menu //
// Menu user profile
ion-toolbar.user-profile {

    .user-avatar {
        width: 68px;
        height: 68px;
    }

    .item-inner {
        border-width: 0;
    }
}

// Custom toolbar (overriding Shadow DOM)
ion-toolbar {
    &.ion-color-primary {
        font-family: 'Cairo';
        display: inline-block;
        --ion-color-base: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary)) !important;
    }
}

// Android card shadow
ion-card {
    &.sc-ion-card-md-h {
        box-shadow: 0 2px 4px rgba(var(--ion-color-dark-rgb), .24);
    }
}



// Custom Menu styles (overriding Shadow DOM)

ion-header {
    ion-toolbar {
        &.ion-color-dark {
            --ion-color-base: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary)) !important;
        }
    }
}


.mto {
    margin-top: 12px;

    ion-icon {
        margin-bottom: -2px;
    }
}
